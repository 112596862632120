import React, { useRef, useState, useEffect } from "react";
import styled, { keyframes } from "styled-components/macro";
import tw from "twin.macro";
import Clip from "images/icon/FileClip.svg";
import Folder from "images/icon/FolderGreen.svg";
import { getFirestore, doc, updateDoc,getDoc ,setDoc} from "firebase/firestore"; // Подключаем функции для работы с Firestore

const Form = styled.form`
    ${tw`space-y-6`}
`;

const Row = styled.div`
    ${tw`grid grid-cols-3 gap-6`}
`;

const Label = styled.label`
    ${tw`text-sm font-semibold mb-1`}
    color: #d3d3d3;
`;

const InputContainer = styled.div`
    ${tw`flex flex-col justify-between`}
`;

const Input = styled.input`
    ${tw`border-2 rounded-lg p-3 text-gray-600`}
    border-color: #D3D3D3;
    transition: border-color 0.3s ease;
    font-size: 16px;
    &:focus {
        border-color: #0ABD19;
    }

    &::placeholder {
        color: #d3d3d3;
        transition: color 0.3s ease;
    }
`;

const Select = styled.select`
    ${tw`border-2 rounded-lg p-3 w-full text-gray-600`}
    border-color: #d3d3d3;
    transition: border-color 0.3s ease;

    &:focus {
        border-color: #0ABD19;
    }
`;

const TextArea = styled.textarea`
    ${tw`border-2 rounded-lg p-3 w-full text-gray-600`}
    border-color: #D3D3D3;
    height: 20px;
    font-size: 16px;
    width: 98%;
    transition: border-color 0.3s ease;

    &:focus {
        border-color: #0ABD19;
    }

    &::placeholder {
        color: #d3d3d3;
        transition: color 0.3s ease;
    }
`;

const AttachPhotoContainer = styled.div`
    ${tw`flex items-center mt-6`}
`;

const FolderContainer = styled.div`
    ${tw`mr-4`}
`;

const AttachPhotoText = styled.span`
    ${tw`ml-2 text-green-500 cursor-pointer`}
    text-decoration: underline;
`;

const AttachPhotoDescription = styled.span`
    ${tw`mt-1 text-gray-400`}
`;

const HiddenFileInput = styled.input`
    display: none;
`;

const FilePreviewRow = styled.div`
    ${tw`flex flex-wrap items-center mt-4`}
`;

const FilePreviewContainer = styled.div`
    ${tw`flex items-center p-2 rounded-lg border mr-2 mb-2 cursor-pointer`}
    border: 1px solid #0ABD19;
    color: #0ABD19;
`;

const FileName = styled.span`
    ${tw`text-sm`}
`;

const StatusContainer = styled.div`
    ${tw`mt-4`}
`;

const BiggerLabel = styled.label`
    ${tw` font-semibold mb-1`}
    color: #d3d3d3;
`;

const CheckboxLabel = styled.label`
    ${tw`flex items-center text-sm font-semibold text-gray-600`}
    font-size: 16px;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
    width: 20px;
    height: 20px;
    ${tw`mr-2 cursor-pointer`}
    appearance: none;
    background-color: #fff;
    border: 2px solid #0ABD19;
    border-radius: 4px;
    transition: all 0.2s ease;

    &:checked {
        background-color: #0ABD19;
        border-color: #0ABD19;
    }

    &:checked::after {
        content: '✔';
        font-size: 14px;
        color: #fff;
        display: block;
        text-align: center;
        line-height: 1.1;
    }
`;

const ButtonContainer = styled.div`
    ${tw`flex justify-between mt-8`}
`;

const GreenButton = styled.button`
    ${tw`text-white py-3 px-16 rounded-lg`}
    transition: transform 0.3s ease;
    background: #0ABD19;
    font-size: 16px;
    border: none;
    &:hover {
        transform: scale(1.1);
    }
`;

const TransparentButton = styled.button`
    ${tw`border-2 border-gray-300 text-gray-600 py-3 px-16 rounded-lg`}
    background-color: transparent;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    font-size: 16px;
    &:hover {
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        transform: translateY(-2px);
    }
`;

const fadeOutPlaceholder = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

const AdmissionDate = styled.input`
    ${tw`border-2 rounded-lg p-4 text-gray-600`}
    border-color: #D3D3D3;
    width: 30%;
    font-size: 16px;
    transition: border-color 0.3s ease;

    &:focus {
        border-color: #0ABD19;
    }

    &::placeholder {
        color: #d3d3d3;
        transition: color 0.3s ease;
        animation: ${fadeOutPlaceholder} 0.3s forwards;
    }
`;

const FormProcessing = ({ parcelData, onUpdateParcelData }) => {
    const fileInputRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [formData, setFormData] = useState(parcelData);

    useEffect(() => {
        setFormData(parcelData); // Обновляем форму, если изменится parcelData
    }, [parcelData]);

    // Calculate volumetric weight when width, length, and height change
    useEffect(() => {
        const { width, length, height } = formData;
        if (width && length && height) {
            const volumetricWeight = (width * length * height) / 5000;
            setFormData((prev) => ({ ...prev, volumetricWeight }));
        }
    }, [formData.width, formData.length, formData.height]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
        onUpdateParcelData({ ...formData, [name]: value });
    };

    const handleAttachPhotoClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    };

    const handleFileRemove = (indexToRemove) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
    };

    // Handle form submission and save the updated data to Firestore
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const db = getFirestore();
            const parcelRef = doc(db, 'parcels', parcelData.id); // Используем parcelData.id для поиска документа

            // Собираем данные, которые нужно обновить
            const updatedData = {
                shelfNumber: formData.shelfNumber,
                actualWeight: formData.actualWeight,
                width: formData.width,
                length: formData.length,
                height: formData.height,
                volumetricWeight: formData.volumetricWeight,
                comment: formData.comment,
                status: "Обработана"  // Меняем статус на "Обработана"
            };

            // Используем updateDoc для обновления существующего документа
            await updateDoc(parcelRef, updatedData);

            alert('Данные успешно сохранены и статус изменен на "Обработана"');
        } catch (error) {
            console.error('Ошибка при сохранении данных: ', error);
        }
    };



    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <InputContainer>
                    <Label>Tracking ID</Label>
                    <Input
                        type="text"
                        name="trackingNumber"
                        placeholder="Tracking ID"
                        value={formData?.trackingNumber || ''}
                        onChange={handleInputChange}
                    />
                </InputContainer>
                <InputContainer>
                    <Label>ID клиента</Label>
                    <Select
                        name="userId"
                        value={formData?.userId || ''}
                        onChange={handleInputChange}
                    >
                        <option value={formData?.userId}>{formData?.userId}</option>
                    </Select>
                </InputContainer>
                <InputContainer>
                    <Label>Shelf number</Label>
                    <Input
                        type="text"
                        name="shelfNumber"
                        placeholder="Shelf number"
                        value={formData?.shelfNumber || ''}
                        onChange={handleInputChange}
                    />
                </InputContainer>
            </Row>

            <Row>
                <InputContainer>
                    <Label>Магазин</Label>
                    <Input
                        type="text"
                        name="storeName"
                        placeholder="Магазин"
                        value={formData?.storeName || ''}
                        onChange={handleInputChange}
                    />
                </InputContainer>
                <InputContainer>
                    <Label>Вес фактический (фунт)</Label>
                    <Input
                        type="number"
                        name="actualWeight"
                        placeholder="Вес фактический"
                        value={formData?.actualWeight || ''}
                        onChange={handleInputChange}
                    />
                </InputContainer>
                <InputContainer>
                    <Label>Ширина (дюйм)</Label>
                    <Input
                        type="number"
                        name="width"
                        placeholder="Ширина"
                        value={formData?.width || ''}
                        onChange={handleInputChange}
                    />
                </InputContainer>
            </Row>

            <Row>
                <InputContainer>
                    <Label>Длина (дюйм)</Label>
                    <Input
                        type="number"
                        name="length"
                        placeholder="Длина"
                        value={formData?.length || ''}
                        onChange={handleInputChange}
                    />
                </InputContainer>
                <InputContainer>
                    <Label>Высота (дюйм)</Label>
                    <Input
                        type="number"
                        name="height"
                        placeholder="Высота"
                        value={formData?.height || ''}
                        onChange={handleInputChange}
                    />
                </InputContainer>
                <InputContainer>
                    <Label>Объемный вес</Label>
                    <Input
                        type="number"
                        name="volumetricWeight"
                        placeholder="Объемный вес"
                        value={formData?.volumetricWeight || ''}
                        readOnly
                    />
                </InputContainer>
            </Row>

            <InputContainer>
                <Label>Дата приема</Label>
                <AdmissionDate
                    type="text"
                    name="dateReceived"
                    placeholder="Дата приема"
                    value={formData?.dateReceived || ''}
                    onChange={handleInputChange}
                />
            </InputContainer>

            <InputContainer>
                <Label>Комментарий оператора</Label>
                <TextArea
                    name="comment"
                    placeholder="Укажите комментарий"
                    value={formData?.comment || ''}
                    onChange={handleInputChange}
                />
                {formData?.service === "Фото в посылке" && (
                    <>
                        <AttachPhotoContainer>
                            <img src={Clip} alt="Clip Icon" />
                            <AttachPhotoText onClick={handleAttachPhotoClick}>
                                Прикрепить фото
                            </AttachPhotoText>
                            <HiddenFileInput
                                type="file"
                                ref={fileInputRef}
                                multiple
                                onChange={handleFileChange}
                            />
                        </AttachPhotoContainer>
                        <AttachPhotoDescription>
                            Для удаления фото - нажмите на него
                        </AttachPhotoDescription>
                        <FilePreviewRow>
                            <FolderContainer>
                                <img src={Folder} alt="Folder Icon"/>
                            </FolderContainer>
                            {selectedFiles.map((file, index) => (
                                <FilePreviewContainer key={index} onClick={() => handleFileRemove(index)}>
                                    <FileName>{`${file.name}`}</FileName>
                                </FilePreviewContainer>
                            ))}
                        </FilePreviewRow>
                    </>
                )}
                <StatusContainer>
                    <BiggerLabel>Status</BiggerLabel>
                    <CheckboxLabel>
                        <Checkbox
                            name="isDamaged"
                            checked={formData?.isDamaged || false}
                            onChange={(e) => setFormData({ ...formData, isDamaged: e.target.checked })}
                        />
                        Повреждена
                    </CheckboxLabel>
                </StatusContainer>
            </InputContainer>

            <ButtonContainer>
                <GreenButton type="submit">Сохранить</GreenButton>
                <TransparentButton type="reset">Сбросить</TransparentButton>
            </ButtonContainer>
        </Form>
    );
};

export default FormProcessing;
