import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import tw from "twin.macro";

const FormContainer = styled.div`${tw`flex justify-center items-center flex-grow`};`;

const Container = styled.div`${tw`w-full max-w-6xl p-8 bg-white shadow-lg rounded-lg border`};`;

const Step3Backend = () => {
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [pointsOfIssue, setPointsOfIssue] = useState([]);
    const [selectedPointCode, setSelectedPointCode] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const [filteredPoints, setFilteredPoints] = useState([]);

    const fetchCities = async () => {
        try {
            const response = await fetch(`https://api.alemtat.kz/web/ext/Catalog/getCitiesByCountry?CountryLocalCode=0001&ApiKey=465ed40c-056a-4677-8f0a-c95794cc016a`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const citiesData = await response.json();
            setCities(citiesData);
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    };

    const fetchPointsOfIssue = async (localityCode) => {
        try {
            const response = await fetch(`https://api.alemtat.kz/web/ext/Catalog/getPointOfIssueOrder?LocalityCode=${localityCode}&ApiKey=465ed40c-056a-4677-8f0a-c95794cc016a`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const pointsData = await response.json();
            setPointsOfIssue(pointsData);
            setFilteredPoints(pointsData.filter(point => point.CityName === selectedCity));
        } catch (error) {
            console.error('Error fetching points of issue:', error);
        }
    };

    const handleCitySelection = (e) => {
        const cityCode = e.target.value;
        setSelectedCity(cityCode);
        fetchPointsOfIssue(cityCode);
    };

    const handleServiceSelection = (service) => {
        setSelectedService(service);
    };

    useEffect(() => {
        fetchCities();
    }, []);

    return (
        <FormContainer>
            <Container>
                <h3>Выберите город:</h3>
                <select onChange={handleCitySelection}>
                    {cities.length > 0 ? (
                        cities.map((city) => (
                            <option key={city.LocalityCode} value={city.LocalityCode}>
                                {city.LocalityName}
                            </option>
                        ))
                    ) : (
                        <option>Loading cities...</option>
                    )}
                </select>

                <h3>Выберите тип доставки:</h3>
                <button onClick={() => handleServiceSelection('Courier')}>Курьер</button>
                <button onClick={() => handleServiceSelection('Postamat')}>Постамат</button>
                <button onClick={() => handleServiceSelection('PVZ')}>ПВЗ</button>

                {selectedService !== 'Courier' && filteredPoints.length > 0 && (
                    <>
                        <h3>Выберите пункт выдачи:</h3>
                        <select onChange={(e) => setSelectedPointCode(e.target.value)}>
                            {filteredPoints.map((point) => (
                                <option key={point.Code} value={point.Code}>
                                    {point.Address} - {point.Name}
                                </option>
                            ))}
                        </select>
                    </>
                )}

                {selectedService === 'Postamat' || selectedService === 'PVZ' ? (
                    <div>
                        <h3>Карта постамата/ПВЗ</h3>
                        <div id="map" style={{ height: '400px', width: '100%' }}></div>
                    </div>
                ) : null}

                <div>
                    <h3>Подтверждение данных:</h3>
                    <p>Город: {selectedCity}</p>
                    <p>Тип доставки: {selectedService}</p>
                    {selectedService !== 'Courier' && (
                        <p>Пункт выдачи: {selectedPointCode}</p>
                    )}
                </div>
            </Container>
        </FormContainer>
    );
};

export default Step3Backend;
