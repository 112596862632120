import React, {useEffect, useState} from 'react';
import Step3Backend from "./STEP3";
import tw from 'twin.macro';
import styled from 'styled-components';
import AnimationRevealPage from '../helpers/AnimationRevealPage';
import MainCabinetHeader from '../components/headers/MainCabinetHeader';
import {ExpecteLink} from "components/misc/Headings.js";
import {MdDelete} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import {collection, deleteDoc, doc, getDocs, getFirestore, query, where,getDoc } from "firebase/firestore";
import {auth} from "../FireBaseConfig";
import plusIcon from "../images/plus.png";



// Page container and layout styles
const PageContainer = styled.div`
    ${tw`min-h-screen flex flex-col`};
`;
const Icon = styled.img`
    width: 18px;
    height: 18px;
    margin-right: 15px;
`;

const HighlightedText = styled.span`
    ${tw`text-primary-500`};
    color: #0ABD19;
`;

const FormContainer = styled.div`
    ${tw`flex justify-center items-center flex-grow`};
`;

const Container = styled.div`
    ${tw`w-full max-w-6xl p-8 bg-white shadow-lg rounded-lg border`};
    border: 2px solid #1BA557;
    border-radius: 15px;
    padding: 40px;
    display: grid;
    margin-top: 0px;
    grid-template-columns: 1fr 1fr; /* Two columns */
    column-gap: 60px; /* Bigger gap between columns for better spacing */
`;

const Column = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
`;

const ColumnLeft = styled.div`
    ${tw`text-gray-600 font-semibold`};
    text-align: left;
`;

const ColumnRight = styled.div`
    ${tw`text-gray-900 font-normal`};
    text-align: right;
`;

const Highlight = styled.span`
    ${tw`text-green-600 font-bold`};
`;

const PageTitle = styled.h1`
    ${tw`text-3xl font-bold `};
    text-align: left;
`;

const StepTitle = tw(ExpecteLink)`w-full mt-2 mb-4`;

const RecipientCard = styled.div`
    ${tw`p-4 bg-white shadow-lg rounded-lg flex flex-col items-center justify-between`};
    border: 2px solid ${props => (props.active ? '#0ABD19' : '#1BA557')};
    width: 260px;
    height: 160px;
    text-align: center;
    margin-right: 20px;
    position: relative;
    cursor: pointer;
`;
const CardTitle = styled.h2`
    ${tw`text-sm font-bold text-black`} // Adjusted text size to be smaller
`;

const CardText = styled.p`
    ${tw`text-xs font-medium text-black`} // Adjusted text size to be smaller
`;
const DeleteIcon = styled.button`
    ${tw`ml-auto `}
    background: none;
    border: none;
    cursor: pointer;
    margin-top: -20px;

    &:hover {
        color: red;
    }
`;
const EditLink = styled.a`
    ${tw`mt-2 text-xs text-gray-700`}
    cursor: pointer;
`;

const Card = styled.div`
    ${tw`p-4 rounded-lg flex flex-col`}
    border-radius: 20px;
    border: 3px solid #869589;
    width: 250px; // Adjusted width to be smaller
    height: 150px; // Adjusted height to be smaller
    box-sizing: border-box;
`;

const CheckMark = styled.div`
    ${tw`absolute  w-4 h-4 rounded-full border-2`};
    border-color: ${props => (props.active ? '#0ABD19' : 'transparent')};
    background-color: ${props => (props.active ? '#0ABD19' : 'transparent')};
`;
const CardsContainer = styled.div`
    ${tw`grid grid-cols-1 md:grid-cols-2 gap-10 max-w-screen-xl mx-auto mb-8`}

`;
const RecipientName = styled.div`
    ${tw`font-bold text-green-700`};
    margin-bottom: 10px;
`;

const PhoneNumber = styled.div`
    ${tw`text-gray-700`};
`;

const EditText = styled.div`
    ${tw`text-gray-400`};
    font-size: 12px;
    margin-top: 10px;
`;

const AddRecipientButton = styled.button`
    ${tw`px-8 py-3 font-bold text-white bg-green-600 rounded-full focus:outline-none`}
    margin-top: 20px;
    max-width: 300px;
`;

const RecipientContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const RecipientList = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
`;
const BottomButtonsContainer = styled.div`
    ${tw`flex justify-start gap-72`}
`;
const CheckBox = styled.input.attrs({type: 'checkbox'})`
    ${tw`absolute`}
    top: 0.5rem; // Equivalent to "top-2"
    right: 0.5rem; // Adjust for right spacing as well
    width: 20px;
    height: 20px;
`;

const CheckboxWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

const Checkbox = styled.input.attrs({type: 'checkbox'})`
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid #0ABD19;
    border-radius: 4px;
    cursor: pointer;
    position: relative;

    &:checked {
        background-color: #0ABD19;
    }

    &:checked::before {
        content: '\\2713'; /* Unicode for checkmark */
        position: absolute;
        top: 0;
        left: 3px;
        font-size: 14px;
        color: white;
    }
`;

const NavLink = styled.a`
    ${tw`text-lg mx-6 my-0 font-medium tracking-wide transition duration-300 pb-1`}
    text-decoration: none;
    color: inherit;

    &:hover, &:focus {
        color: #0ABD19;
        text-decoration: none;
    }
`;
const BottomButton = styled.button`
    ${tw`w-auto bg-green-500 text-white font-bold rounded-lg flex items-center justify-center leading-none focus:outline-none transition duration-300`}
    background-color: #0ABD19;
    border: none;
    width: 220px;
    height: 40px;

    &:hover, &:focus {
        transform: scale(1.1);
    }
`;
//
const StepRowContainer = styled.div`
    ${tw`grid grid-cols-1 lg:grid-cols-2 gap-8 max-w-6xl mx-auto mb-8`};
    padding-top: 30px;
`;

const StepContainer = styled.div`
    ${tw`w-full p-8 bg-white shadow-lg rounded-lg border`};
    border: 2px solid #1BA557;
    border-radius: 15px;
`;

const StepText = styled.p`
    ${tw`text-base text-gray-600`};
    margin-top: 8px;
`;

const BoldGreenText = styled.span`
    ${tw`text-green-600 font-bold text-lg`};
`;

const LinkText = styled.a`
    ${tw`text-green-500 underline cursor-pointer hover:text-green-700`};
    margin-top: 5px;
    font-size: 14px;
`;




const ExpectedLink = () => {

    const [city, setCity] = useState('');  // Для города
    const [receiverName, setReceiverName] = useState('');  // Имя получателя
    const [receiverAddress, setReceiverAddress] = useState('');  // Адрес получателя
    const [selectedService, setSelectedService] = useState('');  // Выбранный тип сервиса
    const [pointsOfIssue, setPointsOfIssue] = useState([]);  // Пункты выдачи
    const [selectedCity, setSelectedCity] = useState('');  // Выбранный город
    const [selectedPointCode, setSelectedPointCode] = useState('');  // Код пункта выдачи
    const [registerWayBill ] = useState('');  // Код пункта выдачи
    // Функции для подтверждения выбора города и отправки накладной
    const confirmCitySelection = () => {
        console.log('Город выбран:', city);
    };

    const submitWayBill = () => {
        console.log('Отправка накладной');
    };

    const [recipients, setRecipients] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate(); // Initialize useNavigat
    const [selectedRecipients, setSelectedRecipients] = useState({});


    useEffect(() => {
        const fetchRecipients = async () => {
            const db = getFirestore();
            const user = auth.currentUser;
            if (user) {
                const q = query(collection(db, "recipients"), where("userId", "==", user.uid));
                const querySnapshot = await getDocs(q);
                const fetchedRecipients = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
                setRecipients(fetchedRecipients);
            }
            setLoading(false);
        };

        fetchRecipients();
    }, []);

    const [showFirstImage, setShowFirstImage] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setShowFirstImage((prev) => !prev);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    const handleEdit = (recipient) => {
        navigate('/RecipientsForm', {state: {recipient}});
    };

    const handleDelete = async (recipientId) => {
        try {
            const db = getFirestore();
            await deleteDoc(doc(db, "recipients", recipientId));
            setRecipients(recipients.filter(recipient => recipient.id !== recipientId));
        } catch (error) {
            console.error("Error deleting recipient: ", error);
        }
    };
    const handleCheckboxChange = (recipientId) => {
        setSelectedRecipients(prevState => ({
            ...prevState,
            [recipientId]: !prevState[recipientId]
        }));
    };



    const parcelData = {
        parcelNumber: "#EPL-00001274",
        trackingNumber: "3323113131",
        name: "ПРОГЕР",
        price: "20000 ₽ ($200)",
        itemsCount: "2 шт",
        volume: "0 (м³)",
        deliveryPrice: "1500 ₽ (15$)",
        weight: "1 кг",
        dimensions: "3 x 0 x 0 см",
        additionalServices: "0 ₽",
    };


    return (
        <AnimationRevealPage>
            <MainCabinetHeader/>
            <PageContainer>

                <FormContainer>
                    <div style={{width: '100%', maxWidth: '1280px'}}>
                        <PageTitle active={true}>Заявка на отправку посылки в Казахстан</PageTitle>
                        <StepTitle active={true}><HighlightedText>Шаг 1. </HighlightedText>Проверьте состав
                            посылки</StepTitle>
                        <Container>
                            {/* Left Column */}
                            <div>
                                <Column>
                                    <ColumnLeft>Номер посылки:</ColumnLeft>
                                    <ColumnRight>{parcelData.parcelNumber}</ColumnRight>
                                </Column>

                                <Column>
                                    <ColumnLeft>Входящий трэк №:</ColumnLeft>
                                    <ColumnRight>{parcelData.trackingNumber}</ColumnRight>
                                </Column>

                                <Column>
                                    <ColumnLeft>Наименование:</ColumnLeft>
                                    <ColumnRight>{parcelData.name}</ColumnRight>
                                </Column>
                            </div>

                            {/* Right Column */}
                            <div>
                                <Column>
                                    <ColumnLeft>Стоимость товаров:</ColumnLeft>
                                    <ColumnRight>{parcelData.price}</ColumnRight>
                                </Column>

                                <Column>
                                    <ColumnLeft>Количество товаров в посылке:</ColumnLeft>
                                    <ColumnRight>{parcelData.itemsCount}</ColumnRight>
                                </Column>

                                <Column>
                                    <ColumnLeft>Объем посылки:</ColumnLeft>
                                    <ColumnRight>{parcelData.volume}</ColumnRight>
                                </Column>

                                {/*<Column>
                                    <ColumnLeft>Доставка до :</ColumnLeft>
                                    <ColumnRight><Highlight>{parcelData.deliveryPrice}</Highlight></ColumnRight>
                                </Column>*/}

                                <Column>
                                    <ColumnLeft>Вес посылки:</ColumnLeft>
                                    <ColumnRight>{parcelData.weight}</ColumnRight>
                                </Column>

                                <Column>
                                    <ColumnLeft>Габариты (В*Ш*Д):</ColumnLeft>
                                    <ColumnRight>{parcelData.dimensions}</ColumnRight>
                                </Column>
                            </div>
                        </Container>
                    </div>
                </FormContainer>

                {/* Step 2: Select Recipient */}
                <FormContainer>
                    <div style={{width: '100%', maxWidth: '1280px'}}>
                        <StepTitle active={true}>
                            <HighlightedText>Шаг 2. </HighlightedText>Укажите получателя
                        </StepTitle>
                        <Container>
                            {/* Wrapper for Cards and Button */}
                            <div
                                style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                                <CardsContainer style={{width: '100%', justifyContent: 'center'}}>
                                    {recipients.map((recipient) => (
                                        <Card key={recipient.id} style={{marginBottom: '20px'}}>
                                            <CheckboxWrapper>
                                                <Checkbox
                                                    checked={!!selectedRecipients[recipient.id]}
                                                    onChange={() => handleCheckboxChange(recipient.id)}
                                                />
                                            </CheckboxWrapper>

                                            <CardTitle>{recipient.name} {recipient.surname}</CardTitle>
                                            <CardText>{recipient.phone}</CardText>
                                            <EditLink onClick={() => handleEdit(recipient)}>Редактировать
                                                данные</EditLink>
                                            <DeleteIcon
                                                onClick={() => handleDelete(recipient.id)}><MdDelete/></DeleteIcon>
                                        </Card>
                                    ))}
                                </CardsContainer>


                                <div style={{display: 'flex', justifyContent: 'start', width: '100%'}}>
                                    <BottomButton style={{marginLeft: '0'}}>
                                        <Icon src={plusIcon} alt="Plus Icon"/>
                                        Добавить получателя
                                    </BottomButton>
                                </div>
                            </div>
                        </Container>
                    </div>
                </FormContainer>
                {/*
                Step 3
                */}

                <Step3Backend

                />



                {/*
                4  5 Step
                */}
                <StepRowContainer>
                    {/* Step 4: Обязательное страхование посылки */}
                    <StepContainer>
                        <h3><BoldGreenText>Сумма страхования - 3%</BoldGreenText></h3>
                        <BottomButton>
                            Добавить
                        </BottomButton>
                    </StepContainer>

                    {/* Step 5: Таможенный лимит */}
                    <StepContainer>
                        <h3><BoldGreenText>Таможенная пошлина </BoldGreenText></h3>
                        <StepText>Беспошлинный лимит РФ на одну посылку 200 €. <LinkText href="#">Открыть калькулятор
                            таможенной пошлины</LinkText></StepText>
                    </StepContainer>
                </StepRowContainer>

            </PageContainer>
        </AnimationRevealPage>
    );
};

export default ExpectedLink;
